<template>
  <div>
    <div :class="`${node.type == 'group' ? 'node-hover' : ''}`">
      <div
        :style="{'margin-left': 25 * depth + 'px'}"
      >
        <div class="d-flex justify-content-between">
          <div class="node" @click="expanded = !expanded">
            <span v-if="hasChildren">{{ expanded ? '&#9660' : '&#9658' }}</span>
            <span v-if="node.type == 'group'">{{ node.name }}</span>
          </div>
        </div>
        <div class="table-responsive mt-2" v-if="expanded && endpointChildren.length > 0" >
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <td colspan="3"></td>
              <td :colspan="Object.keys(budget_break_down).length+1" class="text-center">Budget</td>
              <td :colspan="Object.keys(actual.breakdown).length+1" class="text-center">Actual</td>
            </tr>
            <tr>
              <th style="min-width: 275px">Budget Head</th>
              <th style="min-width: 200px">Description</th>
              <th style="min-width: 200px">Linked Account Head</th>
              <th v-for="(value, key) in budget_break_down" style="min-width: 200px">{{ key }}</th>
              <th>Total</th>
              <th v-for="(actualBreak, interval) in actual.breakdown" style="min-width: 200px">{{ interval }}</th>
              <th>Total</th>
            </tr>
            </thead>
            <tbody>
              <template v-if="endpointChildren.length > 0">
                <BudgetTreeTable
                  v-for="bva in endpointChildren"
                  :bva="bva"
                  :actual="actual"
                  :budget_break_down="budget_break_down"
                  :budget_history="budget_history"
                />
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr class="mb-1 mt-0" v-if="type == 'group'">
    <template v-if="expanded && groupChildren.length > 0">
      <BudgetActualReport
        v-for="item in groupChildren"
        :key="item.name"
        :parent="node"
        :depth="depth + 1"
        :node="item"
        :budget_break_down="budget_break_down"
        :budget_history="budget_history"
        :actual="actual"
      />
    </template>
  </div>
</template>

<script setup>
import {computed, inject, ref} from 'vue';
import figureFormatter from "@/services/utils/figureFormatter";
import BudgetTreeTable from "@/views/page/company/procurement/budget/report/donor-report-bva/BudgetTreeTable"

const $props = defineProps( {
  node: {},
  depth: {
    type: Number,
    default: 0
  },
  parent: {},
  budget_break_down: {},
  budget_history: {},
  actual: {}
});

const emitter = inject('emitter');

let expanded = ref(true);
const {commaFormat} = figureFormatter()

const hasChildren = computed(() => {
  return $props.node.child_with_budget?.length;
});

const type = computed(() => {
  return $props.node.type;
});


const groupChildren = computed(() => {
  return $props.node.child_with_budget?.length > 0 ?
         $props.node.child_with_budget.filter((child) => {
           return (child.type === 'group')
         })
                                                   : [];
})

const endpointChildren = computed(() => {
  return $props.node.child_with_budget?.length > 0 ?
         $props.node.child_with_budget.filter((child) => {
           return (child.type === 'endpoint')
         }) : [];
})

const parentL = computed(() => {
  return parent.child_with_budget?.length ? parent.child_with_budget?.length : 0;
});

const last = computed(() => {
  return parent.child_with_budget ? parent.child_with_budget[parentL - 1] : {};
});

</script>


<style scoped>
.bg-black-light {
  background: #00000024;
}

.rounded-8 {
  border-radius: 8px;
}

.node {
  cursor: pointer;
}

.node-hover:hover {
  background: #fffeec;
}

.node-hover {
  padding: 10px 2px;
}

.cursor-initial {
  cursor: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.w-30 {
  width: 30px;
}

.w-20 {
  width: 20px;
}

.z-999 {
  z-index: 999;
}

.w-44 {
  width: 44px;
}
</style>