<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton :showBtn="false" :showAddNew="false" title="Budget vs Actual Report"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-6">
          <label for="colFormLabel" >Select Project</label>
          <v-select
            placeholder="Select Project"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
            @option:selected="onSelectProject"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel">Budget</label>
          <v-select
            placeholder="Select Budget"
            v-model="budgetMasterId"
            :options="donors"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" >Select Cost Centre</label>
          <v-select
            placeholder="Select Cost Centre"
            v-model="costCentreId"
            :options="costCentres"
            label="name"
            :reduce="name => name.id"
          />
        </div>
      </div>
      <DateQuerySetter
        @onClickGo="getDonBudgetActual"
      >
        <div class="col-sm-4">
          <label class="col-form-label">Interval</label>
          <v-select
            placeholder="Select Interval"
            v-model="interval"
            :options="intervals"
            label="name"
            :reduce="name => name.id"
          />
        </div>
      </DateQuerySetter>
    </div>
    <div class="card p-2" style="min-height: 200px">
    <div class="col-12 text-right">
      <button
          style="min-width: 64px;"
          @click="exportDonorBudgetVariance()"
          class="btn btn-primary btn-block waves-effect waves-float waves-light mr-5"
      > Export Donor Budget Variance
      </button>
      <button
          style="min-width: 64px;"
          @click="exportProjectBudgetVariance()"
          class="btn btn-primary btn-block waves-effect waves-float waves-light"
      > Export Project Budget Variance
      </button>
    </div>
      <BudgetActualReport
        v-if="donorPVAReport.length > 0"
        :style="{opacity: isLoading ? .5 : 1}"
        v-for="(item, i) in donorPVAReport"
        :key="i"
        :node="item"
        :budget_break_down="budgetBreakDown"
        :budget_history="budgetHistory"
        :actual="actual"
      />
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import Loader                             from '@/components/atom/LoaderComponent'
import {computed, inject, onMounted, ref} from "vue";
import handleProjects                     from "@/services/modules/procurement/project";
import {useRoute} from "vue-router";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleBudgets from "@/services/modules/procurement/budget";
import BudgetActualReport from "@/views/page/company/procurement/budget/report/donor-report-bva/BudgetActualReport"
import handleInventory from "@/services/modules/inventory";

const {fetchProjects, fetchProjectDonors} = handleProjects();
const {fetchCostCentreList} = handleCostCentres()
const {fetchDonorBudgetVsActual} = handleBudgets()
const {fetchHome} = handleInventory();

const $route = useRoute();
const showError = inject('showError');
const query = `?company_id=${$route.params.companyId}`;
const token = localStorage.getItem('token');
const projects = ref([]);
const donors = ref([]);
const costCentres = ref([]);
const projectId = ref(null);
const budgetMasterId = ref(null);
const costCentreId = ref(null);
const interval = ref(null);
const isLoading = ref(false);
const donorPVAReport = ref([]);
const budgetBreakDown = ref({});
const budgetHistory = ref({});
const actual = ref({});
const homeContent = ref({});

//computed
const intervals = computed(() => {
  let intervals = [];
  if(homeContent.value.hasOwnProperty('project_intervals')) {
    Object.keys(homeContent.value.project_intervals).forEach( id => {
      intervals.push({
        id: id,
        name: homeContent.value.project_intervals[id],
      })
    })
  }
  return intervals;
})

// Methods
const onSelectProject = () => {
  isLoading.value = true;
  donorPVAReport.value = [];
  budgetHistory.value = {};
  budgetBreakDown.value = {};
  fetchProjectDonors(projectId.value, query)
    .then( res => {
      if(res.status) {
        budgetMasterId.value = null;
        donors.value = res.data;
      } else {
        showError(res.message)
      }
    })
    .catch( err => {
      showError(err.response.message);
    })
    .finally( () => isLoading.value = false)
}
const getDonBudgetActual = () => {
  if(projectId.value === null){
    showError('Please select a project');
    return;
  }
  isLoading.value = true;
  let donorPVAQuery = query + `&budget_master_id=${budgetMasterId.value ?? ''}&cost_centre_id=${costCentreId.value ?? ''}` +
                      `&start_date=${$route.query.start}&end_date=${$route.query.end}`;
  donorPVAQuery += (interval.value !== null) ? `&interval=${interval.value}` : '';
  fetchDonorBudgetVsActual(projectId.value, donorPVAQuery)
    .then( res => {
      if(res.status){
        donorPVAReport.value = res.data.project_accounts;
        budgetHistory.value = res.data.budget_history;
        budgetBreakDown.value = res.data.budget_break_down;
        actual.value = res.data.actual;
      } else {
        showError(res.message)
      }
    })
    .catch( err => {
      showError('Something went wrong')
    })
    .finally( () => isLoading.value = false)
}
const exportProjectBudgetVariance = () => {
  if(projectId.value === null){
    showError('Please select a project');
    return;
  }
  if(interval.value === null){
    showError('Please select an interval');
    return;
  }

  let objQuery = {
    company_id: $route.params.companyId,
    budget_master_id: budgetMasterId.value ?? '',
    cost_centre_id: costCentreId.value ?? '',
    start_date: $route.query.start,
    end_date: $route.query.end,
    interval: interval.value,
    _token: token
  }
  let params = Object.entries(objQuery).map(([key, val]) => `${key}=${val}`).join('&');
  let url = `${process.env.VUE_APP_BASE_URL}/export/donor-report-bva/${projectId.value}?${params}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

const exportDonorBudgetVariance = () => {
  if(projectId.value === null){
    showError('Please select a project');
    return;
  }
  if(interval.value === null){
    showError('Please select an interval');
    return;
  }

  let objQuery = {
    company_id: $route.params.companyId,
    budget_master_id: budgetMasterId.value ?? '',
    cost_centre_id: costCentreId.value ?? '',
    start_date: $route.query.start,
    end_date: $route.query.end,
    interval: interval.value,
    _token: token,
    report_type: "budget-vs-actual",
    report_title: "Export Donor Budget Variance Report"
  }
  let params = Object.entries(objQuery).map(([key, val]) => `${key}=${val}`).join('&');
  let url = `${process.env.VUE_APP_BASE_URL}/export/code-report-bva/${projectId.value}?${params}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

onMounted( () => {
  isLoading.value = true;
  Promise.all([
    fetchHome().then( res => {
      if(res.data) {
        homeContent.value =res.data
      }
    }),
    fetchProjects(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      } else {
        showError(res.message)
      }
    }),
    fetchCostCentreList(query).then( res => {
      if(res.status) {
        costCentres.value = res.data;
      } else {
        showError(res.message)
      }
    })
  ])
    .then ( () => isLoading.value = false)
    .catch( (err) => {
      isLoading.value = false
      showError( err.response.message )
    })
    .finally( () => isLoading.value = false)
})

</script>
