<template>
  <tr>
    <td>{{ bva.name }}</td>
    <td>{{bva.description}}</td>
    <td>{{bva.linked_account}}</td>
    <td class="text-right" v-for="(value, key) in budget_break_down">
      {{ commaFormat( getIntervalValue(key) )}}
    </td>
    <td class="text-right"> {{ commaFormat(getIntervalTotal())}} </td>
    <slot v-if="bva.budget_general !== null">
      <td v-for="(actualBreak, interval) in actual.breakdown">
        {{ commaFormat(getActualIntervalValue(interval))
        }}
      </td>
      <td class="text-right">
        {{
          commaFormat(getActualIntervalTotal())
        }}
      </td>
    </slot>
  </tr>
</template>

<script setup>
  import {defineProps}   from 'vue';
  import figureFormatter from "@/services/utils/figureFormatter";

  const $props = defineProps({
    bva: {},
    actual: {},
    budget_break_down: {},
    budget_history: {},
  })
  const {commaFormat} = figureFormatter()

  const getIntervalValue = (key) => {
    let total = 0;
    if($props.bva.budget_general.length > 0) {
      $props.bva.budget_general.forEach(general => {
          total = total + parseFloat($props.budget_history[general.id][key] ?? 0)
        }
      )
    }
    return total;
  }

  const getIntervalTotal = () => {
    let total = 0;
    if($props.bva.budget_general.length > 0) {
      $props.bva.budget_general.forEach(general => {
          total = total + parseFloat(general.amount ?? '0')
        }
      )
    }
    return total;
  }

  const getActualIntervalValue = (interval) => {
    let total = 0;
    if($props.bva.budget_general.length > 0) {
      $props.bva.budget_general.forEach(general => {
          if($props.actual.report.hasOwnProperty(general.id))
            total = total + parseFloat($props.actual.report[general.id][interval] ?? '0')
        }
      )
    }
    return total;
  }

  const getActualIntervalTotal = () => {
    let total = 0;
    if($props.bva.budget_general.length > 0) {
      $props.bva.budget_general.forEach(general => {
          if($props.actual.report.hasOwnProperty(general.id))
            total = total + parseFloat($props.actual.report[general.id].total ?? '0')
        }
      )
    }
    return total;
  }

</script>